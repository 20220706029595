import React from "react";

import Accent from "../Accent";
import Content from "../Content";

import styles from "./styles.module.less";

const ProductHero = ({ customPhoto = "", icon, header, subhead, alt = "" }) => {
    return (
        <div className={styles.productHero}>
            <div
                className={styles.HeroImg}
                style={{ backgroundImage: `url(${customPhoto})` }}>
                <div className={styles.heroCopy}>
                    <div className={styles.iconContainer}>
                        <img data-blink-src={icon} alt={alt} />
                    </div>
                    <div className={styles.copy}>
                        <div className={styles.header}>
                            <h1>{header}</h1>
                        </div>
                        <Accent />
                        <Content className={styles.subhead} content={subhead} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProductHero;
